import React from "react";
import {TextField} from "@material-ui/core";

const RenderMessage=({input, label, type, placeholder, name, meta,classes})=>{
    let errorStatus = false;
    meta.error && meta.touched ? errorStatus=true:errorStatus=false;

    return (
        <TextField
            id={name}
            label={label}
            multiline
            rowsMax='4'
            //defaultValue={placeholder}
            margin={'normal'}
            error={errorStatus}
            fullWidth
            {...input}
        />
    )
};

export default RenderMessage;