const colors={
    dSBlue:'#143a5c',
    dSGold:'#ab8b60',
    blueGradient1:['#3394e8','#256ba8','#143a5c','#174269',"#0f2a42"],
    goldGradient1:['#917651','#b89567','#ab8b60','#f7c88b','#6b573c'],
    grey:'#8C8C8C',
    darkGrey:'#525259',
    yellow:'#f0ad4e',
    green:'#5cb85c',
    lightGrey:'#C7C7C7',
    colorPallet:["#FF011A","#3DBEFF",'#197FB3','#FF68A7','#12B31D','#000000', '#FFFFFF','#B026FF','#FFD92B','#FF6522','#E8024E','#010052','#0FFFF7','#FFD05E','#FCDEFF']

};

export default colors;