import React from "react";
import {Router,Route}from 'react-router-dom';
import history from "./history";

//components
import './assets/css/material-dashboard-react.css';
import Home from "./Screens/Home";
import About from "./Screens/About";
import Contact from "./Screens/Contact";
import Terms from "./Screens/Terms";
import Privacy from "./Screens/Privacy";


const App =()=>{
    return (
        <div>
            <Router history={history}>
                <Route exact path={'/'} component={Home}/>
                <Route path={'/about'} component={About}/>
                <Route path={'/contact'} component={Contact}/>
                <Route path={'/terms'} component={Terms}/>
                <Route path={'/privacy'} component={Privacy}/>
            </Router>
        </div>
    )
}
export default App;