import React from "react";
import classNames from 'classnames';
import withStyles from "@material-ui/core/styles/withStyles";
import {Link} from "react-router-dom";

//core components
import Header from "../components/Header/Header";
import HeaderLinks from "../components/Header/HeaderLinks";
import Footer from "../components/Footer/FooterMain";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";

import landingPageStyle from "../assets/jss/material-kit-react/views/landingPage";
import Parallax from "../components/Parallax/Parallax";
import {BrandLinkWhite} from "../components/BrandLink";
import {DSDivider} from "../components/Divider";
import SiteLinks from "../components/SitelInks";
import colors from "../Variables";
import WhiteFooter from "../components/Footer/WhiteFooter";

class About extends React.Component{
    componentDidMount() {
        window.scrollTo(0,0);
    }

    render(){
        const { classes, ...rest } = this.props;
        return (
            <div>
                <Header
                    absolute
                    color={"transparent"}
                    brand={<BrandLinkWhite/>}
                    rightLinks={<HeaderLinks/>}
                    {...rest}
                />
                <Parallax small filter image={require("../assets/img/backgrounds/About-bg.jpeg")}>
                    <div className={classes.container}>
                        <GridContainer>
                            <GridItem xs={12} container alignItems={'center'} justify={'center'} direction={'row'}>
                                <h2><b>About Us</b></h2>
                            </GridItem>
                        </GridContainer>
                    </div>
                </Parallax>
                <div className={classNames(classes.main)} style={{paddingTop:'3%', paddingBottom:"3%"}}>
                    {/*About us content*/}
                    <div className={classes.section}>
                        <div style={{padding:'3%'}}>
                            <p>
                                <span style={{color:colors.dSGold}}>Dance Suite</span> as a concept was first born at the rebirth of <span><a href='https://rumbainthejungle.com/' target="_blank" style={{color:colors.dSBlue}}>Rhumba in the Jungle</a></span> hosted at sun city in 2017,
                                where the nature of event attracted large members of the dancing community to participate.
                                Due to the volume of entries, manually processing these marks proved challenging,
                                especially to encompass the speed of the event.
                            </p><br/>
                            <p>
                                With over 16yrs experience as a scrutineer for the various dance organisations in South Africa,
                                Kyle Winter relied on his experience and with the help of the Rhumba in the Jungle committee,
                                was able to develop DanceSuite, a revolutionary digital dance adjudication system.
                            </p><br/>
                            <p>
                                Making use of the custom developed adjudicator platform and android tablets,
                                the adjudicators enter marks digitally, saving many problems.
                                Going green is only one of many benefits to a digital system.
                                With data being stored in the cloud the digital age of marking prevents errors such as,
                                duplicate numbers on callbacks, calling incorrect numbers etc.
                                Paired with the custom developed marshal application and an organised marshal area errors are reduced even more significantly.
                            </p><br/>
                            <p>
                                Scrutineers and Chair’s access with system through a secure web application, giving them full control over the event,
                                marks and results.With the speed of technology available, scrutineers can almost process marks before the section has left the floor.
                                This improves processing time and gives the dancers more notification for callbacks between rounds.
                            </p><br/>
                            <p>
                                For any more information <span ><Link to={'/contact'} style={{color:colors.dSGold}}>contact us</Link></span>.

                            </p>
                        </div>
                    </div>
                </div>
                <WhiteFooter/>
            </div>
        )
    }
}


export default withStyles(landingPageStyle)(About);