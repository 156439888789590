import React from 'react';
import ReactDOM from 'react-dom';
import {Provider}from 'react-redux';
import {createStore,applyMiddleware,compose} from "redux";
import reduxThunk from 'redux-thunk';


// components
import './assets/css/material-dashboard-react.css';
import App from './App';
import Reducers from './Store/Reducers/index';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    Reducers,
    composeEnhancers(applyMiddleware(reduxThunk))
)


ReactDOM.render(
  <Provider store={store}>
     <App />
  </Provider>,
  document.getElementById('root')
);

