import { container, title } from "../../material-kit-react.jsx";

const HomePageStyle = {
    container: {
        zIndex: "12",
        color: "#FFFFFF",
        ...container
    },
    title: {
        ...title,
        display: "inline-block",
        position: "relative",
        marginTop: "30px",
        minHeight: "32px",
        color: "#FFFFFF",
        textDecoration: "none"
    },
    subtitle: {
        fontSize: "1.313rem",
        maxWidth: "500px",
        margin: "10px auto 0"
    },
    main: {
        background: "#FFFFFF",
        position: "relative",
        zIndex: "3"
    },
    mainRaised: {
        margin: "-60px 30px 0px",
        borderRadius: "6px",
        boxShadow:
            "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    },
    parallaxBrand:{
        maxHeight:190,
        height:'100rem'
    },
    parallaxTitle:{
        fontSize:'2.5rem',
        lineHeight:'2rem',
        textAlign:'center'
    },
    parallaxSubTitle:{
        fontSize:'1rem',
        lineHeight:'1rem',
        textAlign:'center',
        marginTop:'-10px'
    },
    parallaxButton:{
        //paddingTop:500,
        bottom:0,

    },
    parallaxButtonIcon:{
        paddingLeft:5,
        marginLeft:5,
        fontSize:'1rem'
    }
};

export default HomePageStyle;
