import React,{useEffect, useState} from 'react';
import {connect}from 'react-redux';
//components
import {GetTerms} from "../Store/Actions";
import Disclaimer from "../components/Disclaimers";

const Terms=({data,loaded,GetTerms})=>{

    const [error,setError]=useState(null);

    useEffect(()=>{
            let subscribed=true;
           if(!loaded){
             GetTerms(res=>{
               if(subscribed && res.status===2){
                   setError(res.msg);
               }
             })
           }

           return ()=>subscribed=false;
    },[])

    return (
       <Disclaimer
        data={data}
        loaded={loaded}
        error={error}
        type={'terms'}
       />
    )

}
const mapStateToProps=(state)=>{
    return {
        data:state.Disclaimers.terms,
        loaded:state.Disclaimers.termsLoaded
    }
}
export default connect(mapStateToProps,{GetTerms})(Terms);