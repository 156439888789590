/*eslint-disable*/
import React from "react";
import { makeStyles } from "@material-ui/core";

import footerStyle from "../../assets/jss/material-dashboard-react/components/footerStyle.js";
const useStyles = makeStyles(footerStyle);
export default function Footer() {
  const classes = useStyles()
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div style={{textAlign:'center'}}>
          &copy; {1900 + new Date().getYear()}  Dance Suite
        </div>
      </div>
    </footer>
  );
}

