import React,{useEffect} from "react";
// component
import WhiteFooter from "../Footer/WhiteFooter";
import Parallax from "../Parallax/Parallax";
import GridContainer from "../Grid/GridContainer";
import ClassNames from "classnames";
import Danger from "../Typography/Danger";
import Interweave from "interweave";
import LinearLoading from "../Loading/LinearLoading";
import {withStyles} from "@material-ui/core";
import termsPageStyle from "../../assets/jss/material-kit-react/views/termsPageStyle";
import {BrandLinkWhite} from "../BrandLink";
import HeaderLinks from "../Header/HeaderLinks";
import Header from "../Header/Header";

const privacyBg=require('../../assets/img/backgrounds/Privacy-bg.jpg');
const termsBg=require('../../assets/img/backgrounds/Terms-bg.jpg');

const Disclaimer=({type, classes, loaded,data,error,rest})=>{

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    return (
        <div>
            <Header
                absolute
                color={"transparent"}
                brand={<BrandLinkWhite/>}
                rightLinks={<HeaderLinks/>}
                {...rest}
            />
            <Parallax filter image={type==='terms'?termsBg:privacyBg} style={{height:350}}>
                <div className={classes.container}>
                    <GridContainer justify={'center'} alignItems={'center'} direction={'column'}>
                        <h2><b>{type==='terms'?'Terms & Conditions':'Privacy'}</b></h2>
                    </GridContainer>
                </div>
            </Parallax>
            <div className={ClassNames(classes.section)}>
                <div className={ClassNames(classes.main, classes.mainRaised)}>
                    {error??<Danger>{error}</Danger>}
                    <div style={{margin:25, paddingTop:25,paddingBottom:25}}>
                        {loaded?
                            <Interweave content={data}/>:
                            <LinearLoading show={true}/>
                        }
                    </div>
                </div>
            </div>
            <WhiteFooter/>
        </div>
    )
}
export default withStyles(termsPageStyle)(Disclaimer);
