import React from "react";
import classNames from 'classnames';
import withStyles from "@material-ui/core/styles/withStyles";
import {connect}from 'react-redux';
import {reduxForm, Field} from "redux-form";
import {Input}from '@material-ui/icons'
//core components
import Header from "../components/Header/Header";
import HeaderLinks from "../components/Header/HeaderLinks";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import Parallax from "../components/Parallax/Parallax";
import {BrandLinkWhite} from "../components/BrandLink";
import ContactPageStyle from '../assets/jss/material-kit-react/views/ContactPageStyle';
import RenderInputs from "../Functions/RenderInputs";
import Button from "../components/CustomButtons/Button";

import colors from "../Variables";
import RenderMessage from "../Functions/RenderMessage";
import Loading from "../components/Loading";
import {sendContactUsInfo} from '../Store/Actions';
import Success from "../components/Typography/Success";
import Danger from "../components/Typography/Danger";
import WhiteFooter from "../components/Footer/WhiteFooter";
class Contact extends React.Component{

    state={
        captcha:false,
        loading:false,
        successMessage:'',
        errorMessage:''
    };

    componentDidMount() {
        window.scrollTo(0,0);
    }


    onSubmit=(formValues)=>{
        if(!this.state.captcha){
            this.setState({loading:true});

            this.props.sendContactUsInfo(formValues,res=>{
                res.status===1?
                    this.setState({loading:false,successMessage:res.msg,errorMessage:''}):
                    this.setState({loading:false,successMessage:'',errorMessage:res.msg})
            })
        }

    };


    render(){
        const { classes, ...rest } = this.props;

        return (
            <div>
                <Loading
                    show={this.state.loading}
                />
                <Header
                    absolute
                    color={"transparent"}
                    brand={<BrandLinkWhite/>}
                    rightLinks={<HeaderLinks/>}
                    {...rest}
                />
                <Parallax style={{height:350}}  filter image={require("../assets/img/backgrounds/contempBg.jpg")}>
                    <div className={classes.container}>
                        <GridContainer>
                            <GridItem xs={12} container alignItems={'center'} justify={'center'} direction={'row'}>
                                <h2><b>Contact Us</b></h2>
                            </GridItem>
                        </GridContainer>
                    </div>
                </Parallax>
                <div className={classes.section}>
                    <div className={classNames(classes.main,classes.mainRaised)}>
                        <GridContainer justify={'center'}>
                            <GridItem xs={12} md={8} container justify={'center'} alignItems={'center'}>
                                <h3 className={classes.title} style={{color:colors.dSBlue}}><b>Get in touch with us</b></h3>
                                <p style={{textAlign:'center',color:colors.dSBlue}}>Simply fill out the form below and well get back to you as soon as possible.</p><br/>
                                <Success>{this.state.successMessage}</Success>
                                <Danger>{this.state.errorMessage}</Danger>
                                <form className={classes.formDiv} onSubmit={this.props.handleSubmit(this.onSubmit)}>
                                    <Field id='name' type='text' placeholder='Full Name' name='name' label='Full Name' component={RenderInputs}/>
                                    <Field id='email' type='email' placeholder='name@email.com' name='email' label='Email' component={RenderInputs}/>
                                    <Field id='message' type='message'  placeholder='Your message here' name='message' label='Message' component={RenderMessage}/>
                                    <div style={{marginTop:'3%'}}>
                                        <Button type={'submit'} color='success' size='sm' round block >
                                            <Input/>
                                            Submit
                                        </Button>
                                    </div>
                                </form>
                            </GridItem>
                        </GridContainer>
                    </div>
                </div>
                <WhiteFooter/>
            </div>
        )
    }
}
const validate=(formValues)=>{
    const errors={};
    const {email, name,message}=formValues;

    if(!email){
        errors.email='Please enter a your email!';
    }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)){
        errors.email='Please enter a valid email address';
    }
    if(!name){
        errors.name ='You must enter a name!'
    }
    if(!message){
        errors.message='You must enter a message!'
    }

    return errors;
};

const form =reduxForm({
    form:'contactForm',
    validate
})(Contact);


export default connect(null,{sendContactUsInfo})(withStyles(ContactPageStyle)(form));