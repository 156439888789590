import React,{useState,useEffect} from "react";
import {connect}from 'react-redux';
// components
import {GetPrivacy} from "../Store/Actions";
import Disclaimer from "../components/Disclaimers";


const Privacy=({loaded,data,GetPrivacy})=>{

    const[error,setError]=useState(null);

    useEffect(()=>{
        let Subscribed=true;

        if(!loaded){
            GetPrivacy(res=>{
                if(res.status===2 && Subscribed){
                    setError(res.msg);
                }
            })
        }
        return ()=>Subscribed=false;
    },[])

    return (
        <Disclaimer
            loaded={loaded}
            data={data}
            error={error}
            type={'privacy'}
        />
    )
}
const mapStateToProps=(state)=>{
    return {
        data:state.Disclaimers.privacy,
        loaded:state.Disclaimers.privacyLoaded
    }
}

export default connect(mapStateToProps,{GetPrivacy})(Privacy);

