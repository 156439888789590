import React from "react";
import classNames from 'classnames';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import {ListAlt,List, TabletAndroid,Security, Cast,SpeakerPhone, ExpandMore} from "@material-ui/icons";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";

//core components
import Header from "../components/Header/Header";
import HeaderLinks from "../components/Header/HeaderLinks";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import {BrandLink, BrandLinkWhite} from "../components/BrandLink";
import Parallax from "../components/Parallax/Parallax";
import InfoArea from "../components/InfoArea/InfoArea";
import {DSDivider} from "../components/Divider";
import colors from "../Variables";
import Button from "../components/CustomButtons/Button";
import WhiteFooter from "../components/Footer/WhiteFooter";
import HomePageStyle from "../assets/jss/material-kit-react/views/HomePageStyle";

class Home extends React.Component{
    constructor(props) {
        super();
        this.myRef= React.createRef();
    }

    ScrollTo=()=> window.scrollTo({top:this.myRef.current.offsetTop,behavior:'smooth'});


    render(){
        const { classes, ...rest } = this.props;
        return (
            <div>
               <Header
                    absolute
                    brand={<BrandLink/>}
                    rightLinks={<HeaderLinks/>}
                    {...rest}
               />
               <Parallax  filter image={require("../assets/img/backgrounds/BallroomDress-bg.jpg")}>
                   <div className={classes.container} >
                        <GridContainer justify={'center'} alignItems={'center'} direction={'column'}>
                            <img className={classes.parallaxBrand} alt={'header-computers'} src={require('../assets/img/Main_LogoWhite.png')}/>
                            <h2 className={classes.parallaxTitle}><b>Welcome to Dance Suite</b></h2>
                            <p className={classes.parallaxSubTitle}>The online Dance adjudication system</p>

                        </GridContainer>
                        <GridContainer style={{left:'50%',right:'50%',textAlign:'center',bottom:100,position:'absolute'}} justify={'center'}>
                            <Button
                                onClick={this.ScrollTo}
                                color={'info'}
                                size={'sm'}
                                round
                            >
                                Learn More <ExpandMore className={classes.parallaxButtonIcon}/>
                            </Button>
                        </GridContainer>



                       {
                           /*
                           <GridContainer>
                        <GridItem xs={12} sm={12} md={6} style={{color:'white'}} container justify="center" direction="row">
                            <Hidden smDown>
                             <img style={{flex:1,maxHeight:'50%',paddingTop:'5%'}} src={require('../assets/img/Main_LogoWhite.png')}/>
                            </Hidden>
                            <div style={{textAlign:'center',justifyContent:'center'}}>
                                <h2><b>Welcome to Dance Suite</b></h2>
                                <p>Your online dance adjudication system</p>
                            </div>

                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} container alignItems="center" justify="center" direction="row">
                            <img
                                style={{maxWidth:'100%'}}
                                src={require('../assets/img/computerGraphic.png')}
                            />

                        </GridItem>
                    </GridContainer>
                           * */
                       }

                   </div>
               </Parallax>

                <div ref={this.myRef} className={classNames(classes.main)} style={{paddingTop:'3%', paddingBottom:"3%"}}>
                    {/*Features sections*/}
                    <div className={classes.section}>
                            <GridContainer>
                                <GridItem xs={12} container alignItems={'center'} justify={'center'} direction={'row'}  >
                                    <h3 className={classes.title} style={{color:colors.dSBlue}}>Features</h3>
                                    <p style={{textAlign:'center', paddingLeft:'5%', paddingRight:'5%'}}>
                                        Dance Suite is an online based dance adjudication software that works with a realtime backend,
                                        giving all the users connected to is access to the relevant data in realtime.
                                        Below are some of the advantages and features of dance suite.
                                    </p>
                                </GridItem>
                            </GridContainer>
                            <div className={classes.container}>
                                 <GridContainer justify={'center'} direction="row">
                                    <GridItem xs={12} md={4}>
                                        <InfoArea
                                            title={'Realtime Database'}
                                            icon={ListAlt}
                                            iconColor={'info'}
                                            description={'Once users have been authenticated, they subscribe to the backend and receive the latest version of data constantly, giving all users an un interrupted experience.'}
                                            viertical
                                        />
                                    </GridItem>
                                    <GridItem xs={12} md={4}>
                                        <InfoArea
                                            title={'Instant Results'}
                                            icon={List}
                                            iconColor={'warning'}
                                            description={'Results are stored on the server and can be accessed at any time by the officials while the event is running, when complete, all results become immediately available for the public to view online'}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} md={4}>
                                        <InfoArea
                                            title={'Paperless System'}
                                            icon={TabletAndroid}
                                            iconColor={'primary'}
                                            description={"A complete paperless system with the introduction of tablet marking. Adjudicators mark using the cross platform Dance Suite Adjudicator mobile application, whilst the scrutineering and checking is completed via an web portal."}
                                        />
                                    </GridItem>
                                     <GridItem xs={12} md={4}>
                                         <InfoArea
                                             title={'Security'}
                                             icon={Security}
                                             iconColor={'rose'}
                                             description={"Data is only accessed through authenticated web portals. Safely secured by firewalls, stored, processed and backed up systematically on remote server eliminating data loss should a device crash."}
                                         />
                                     </GridItem>
                                     <GridItem xs={12} md={4}>
                                         <InfoArea
                                             title={'Live Events'}
                                             icon={Cast}
                                             iconColor={'success'}
                                             description={"A responsive web page displays running order, sections and dancers for each round of all live events."}
                                         />
                                     </GridItem>
                                     <GridItem xs={12} md={4}>
                                         <InfoArea
                                             title={'Realtime Marshalling'}
                                             icon={SpeakerPhone}
                                             iconColor={'warning'}
                                             description={"Marshalling of dancers is completed via the cross platform Dance Suite Marshall mobile application. Before sections take the floor officials have a clear indication of the dancers participating in that section."}
                                         />
                                     </GridItem>
                            </GridContainer>
                            </div>
                    </div>
                    <DSDivider/>
                    {/* DS Environment*/}
                    <div className={classes.container}>
                        <GridContainer classsName={classes.section}>
                            <GridItem xs={12} container justify="center" alignContent='center' alignItems='center' direction={'column'}>
                                <h3 className={classes.title}  style={{color:colors.dSBlue,textAlign:'center'}}>Dance Suite Event Environment</h3>
                                {/* DS Environment image*/}
                                <img alt={'dance-suite-environment'} style={{maxWidth:'80%'}} src={require('../assets/img/Dance-Suite-Enviroment.jpg')}/>
                            </GridItem>
                        </GridContainer>
                    </div>
                    <DSDivider/>
                    {/* Digital Marking*/}
                    <div className={classes.section} style={{margin:'3%'}}>
                        <GridContainer>
                            <GridItem xs={12} md={6}  container justify="center" alignItems='center'>
                                {/* Digital marking image*/}
                                <img alt={'dance-suite-adjudicator'} style={{maxWidth:'80%'}} src={require('../assets/img/dance-suite-adjudicator.png')}/>
                            </GridItem>
                            <GridItem xs={12} md={6} container justify="center" alignItems='center'>
                                <div style={{padding:20}}>
                                    <h3 className={classes.title} style={{color:colors.dSBlue}}>Digital Marking</h3>
                                    <p>Digital marking is the way of the future, adjudicators judge using tablets that have the user friendly cross platform Dance Suite Adjudicator Mobile App pre-loaded onto it, some of the benefits of using this system are:</p>
                                    <ul>
                                        <li>All dancers are pre-loaded into the Dance Suite Adjudicator App in realtime when section becomes available.</li>
                                        <li>Judges cannot duplicate numbers</li>
                                        <li>Pre-set round types, Order of Merit, Social/Pro-am, Multidance, contested, uncontested</li>
                                        <li>Human error validation, before marks are captured the system ensures all marks required are given by the adjudicator</li>
                                        <li>All marks are backed up to devices as well as sent to the server for security and audit</li>
                                        <li>Secure adjudicator authentication </li>
                                    </ul>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                    <DSDivider/>
                    {/* Scrutineering web app*/}
                    <div className={classes.section}  style={{margin:'3%'}}>
                        <GridContainer>
                            <GridItem xs={12} md={6} container justify="center" alignItems='center'>
                                <div style={{padding:20}}>
                                    <h3 className={classes.title} style={{color:colors.dSBlue}}>Scrutineering Web App</h3>
                                    <p>The Scrutineer has all control over the event, from setting up sections, creating rounds to publishing results when then event is completed. The scrutineering web application uses the latest in web technologies to ensure the event’s database kept up to date and the event runs smoothly. Some of the powerful features of this application include:</p>
                                    <ul>
                                        <li>Realtime database integration</li>
                                        <li>Adjudicator monitoring</li>
                                        <li>Secure multilevel authentication</li>
                                        <li>Adjudicator status reporting</li>
                                        <li>Realtime results, once all the adjudicators marks have been entered the scrutineer can view and process the results instantly.</li>
                                        <li>Process and check logs</li>
                                        <li>Powerful dashboard containing all the event data in one place.</li>
                                    </ul>
                                </div>
                            </GridItem>
                            <GridItem xs={12} md={6} container justify="center" alignItems='center'>
                                {/* DS scrutineer image*/}
                                <img style={{maxWidth:'80%'}}  alt={'dance-suite-scrutineer'} src={require('../assets/img/Dance-Suite-Scrutineer.png')}/>
                            </GridItem>
                        </GridContainer>
                    </div>
                    <DSDivider/>
                    {/* free demo version*/}
                    <div className={classes.section}>
                        <GridContainer style={{padding:20}} justify={'center'}>
                            <div style={{textAlign:'center'}}>
                                <h3 className={classes.title} style={{color:colors.dSBlue}}>Get in touch for a Live demo</h3>
                                <Link to={'/contact'}>
                                    <Button color='info' round block>Contact Us</Button>
                                </Link>
                            </div>
                        </GridContainer>
                    </div>
                </div>
                {/*Footer*/}
                <WhiteFooter/>
            </div>
        )
    }
}
Home.propTypes={
    classes:PropTypes.object
};


export default connect(null)(withStyles(HomePageStyle)(Home));