import React from "react";
import {LinearProgress, withStyles} from "@material-ui/core";
import colors from "../../Variables";

const ColorLinearProgress = withStyles({
    colorPrimary: {
        backgroundColor: colors.dSGold,
    },
    barColorPrimary: {
        backgroundColor: colors.dSBlue,
    },
})(LinearProgress);

const LinearLoading=({show})=>{
    return show?
        <ColorLinearProgress/>:null
}
export default LinearLoading;