import {LOAD_PRIVACY, LOAD_TERMS} from "../types";

const INITIAL_STATE={
    terms:null,
    termsLoaded:false,
    privacy:null,
    privacyLoaded:null
}

export default (state=INITIAL_STATE,action)=>{
    switch(action.type){
        case LOAD_TERMS:
            return {...state,terms:action.payload,termsLoaded: true}
        case LOAD_PRIVACY:
            return {...state,privacy: action.payload,privacyLoaded: true}
        default:
            return {...state};
    }
}