import axios from 'axios';
import {dbFire} from "../../Firebase/";
import {LOAD_PRIVACY,LOAD_TERMS} from "../types";

export const sendContactUsInfo=(formValues,callback)=>async(dispatch)=>{

    const {name,email,message}=formValues;

    const response = await axios.post('https://us-central1-dance-suite.cloudfunctions.net/contactUs',{name,email,message});

    if(response.data.status===1){
        callback({status:1,msg:response.data.msg})
    }else{
        callback({status:2,msg:response.data.msg})
    }


};
export const GetTerms =(callback)=>dispatch=>{
    const ref = dbFire.collection('disclaimers').doc('terms');
    ref.get()
        .then(doc=>{
            if(doc.exists){
                const {content}=doc.data();
                dispatch({type:LOAD_TERMS,payload:content});
                callback({status:1});

            }else{
                callback({status:2,msg:'Unable to get terms'})
            }
        })
        .catch(err=>err?callback({status:2,msg:err.message}):null)
};
export const GetPrivacy=(callback)=>dispatch=>{
    const ref = dbFire.collection('disclaimers').doc('privacy');
    ref.get()
        .then(doc=>{
            if(doc.exists){
                const {content}=doc.data();
                dispatch({type:LOAD_PRIVACY,payload:content});
                callback({status:1});

            }else{
                callback({status:2,msg:'Unable to get terms'})
            }
        })
        .catch(err=>err?callback({status:2,msg:err.message}):null)
};