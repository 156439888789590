import React from "react";
import {Link} from 'react-router-dom';

const logo = require('../../assets/img/Main_Logo.png');
const whiteLogo = require('../../assets/img/Main_LogoWhite.png');

export const BrandLink =()=>{
    return(
        <Link to={'/'}>
            <img src={logo} alt={'Logo'} style={{height:30,width:60}}/>
        </Link>
    )
};

export const BrandLinkWhite =()=>{
    return(
        <Link to={'/'}>
            <img src={whiteLogo} alt={'Logo'} style={{height:30,width:60}}/>
        </Link>
    )
};

