import React from "react";
import {Divider} from "@material-ui/core";
export const DSDivider=()=>{
    const dividerStyle={
        borderTop:'2px solid #ab8b60',
        //backgroundImage:'linear-gradient(to right,#917651,#6b573c)',
        borderRadius:5,
        boxShadow: '0px 1px 1px -1px rgba(0, 0, 0, 0.5)'
    };
    return (
        <Divider
            style={dividerStyle}
            variant={'middle'}
        />
    )
};