import * as firebase from 'firebase/app';
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCAJC8UcOa8Xh6jX546nXRC1RdFjTilTKg",
    authDomain: "dance-suite.firebaseapp.com",
    databaseURL: "https://dance-suite.firebaseio.com",
    projectId: "dance-suite",
    storageBucket: "dance-suite.appspot.com",
    messagingSenderId: "565453165973",
    appId: "1:565453165973:web:e5eafefc359a3eee"
};

export const app = firebase.initializeApp(firebaseConfig);
export const auth = app.auth();
export const database = app.database();
export const storage = app.storage().ref();
export const dbFire= app.firestore();